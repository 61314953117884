
*{
  margin: 0;
  padding:0;
  box-sizing: border-box;
}
body{

 background-color: white;
} 



.navbar{
  
  font-family: "Roboto", sans-serif;
  box-shadow: 0px 2px 0px rgb(170, 167, 167);
  
} 

.active {
  border-bottom: 2px solid #F19A17 ;
}
.navbar-nav a:hover {
  color :#F19A17 ;

}
.nav-link-hover{
 color:#F19A17 ;
}
.navbar-nav a{
  color: black;
  font-weight: 500;
  font-family: Arial, Helvetica, sans-serif;
}

.logo{
  height:90px; 
  width:90px;
  /* border-radius: 100%; */
}


/* login css  start*/
/* .login{
  width: 100%;
  height: 300px;
  background-color:#F19A17 ;
}
.login-bg{
  background-color:#a36913 ;
} */


.password-icon{
  margin-top: -35px;
  margin-right: 20px;
  font-size: 20px;
}
/* login css  end*/


/* Start Services Dropdown  CSS */
.dropbtn {
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  margin-top: -10px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropbtn {background-color: #3e8e41;}

/* End Dropdown Down Services CSS */

/* Start Nabvar Toggle  */
.navbar-toggle-btn{

  /* font-size: 25px; */
  border-radius: 5px;

}
.navbar-toggle{
  background-color: white;
 font-size: 25px;
 border-radius: 4px;
 color: black;
}
/* End Nabvar  Toggle */


/* Banner css start */
.banner{
  width: 100%;
  height: 350px;
  
}
.banner img{
  width: 100%;
  height:320px;
  object-fit:fill;
}

.web-banner{
  height: 300px;
  width: 100%;
}
.web-banner img{
  object-fit:fill;
  width: 100%;
  height: 300px;
}
/* Banner css end */


.line1{
  height: 50%;
  width: 100%;
  background-color: navy;

}
.line2{
  height: 50%;
  width: 100%;
  background-color: #F19A17;
}

.box-1{
  width: 100%;
  height: 100%;
  background-color:white;
}

.box-2{
  height: 125px;
  width: 50px;
  background-color: #5ca1e1;
}
.box-3{
  height: 125px;
  background-color: #c6d6e6;
}
.box-4{
  height: 125px;
  width: 50px;
  background-color: #e15ca5;
}
.box-5{
  height: 125px;
  width: 50px;
  background-color: #101316;
}
/* Banner css end */

/*start about section css */

/* section1 */
.section-1{
/* background: #FCF2F2;*/
height: 500px;
width: 100%;
color: whitesmoke;
}

/* error for Background Color  */
/* left side  */


.text-1{
  color: #000;
  margin-top: 20px;
  text-align: center;
  border-radius: 10px;
  margin-right: 20px;
 font-size: 2.5rem;  
}
.text-2{
  color:black;
  font-weight: 600;
  margin-left: 20px;
  
}
.text-2{
color:black;
font-weight: 600;
margin-top: 20px;
}
.text-3{
color:rgb(43, 43, 92);

font-size:medium;
font-weight:350;
margin:0 20px 20px 20px;
}
.text-4{
color:#F19A17;

}
.text-5{
  font-size:1rem;
}
.left-side{
width: 100%;
}

.about-pgc-text{
display: flex;
align-items:center ;
justify-content: center;
flex-direction: column;
text-align: inherit;
}

.about-item{
height: 200px;
width: 200px;
background: white;
display: flex;
align-items:center ;
justify-content: center;
flex-direction: column;
border-radius: 20px;
box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
margin: 20px;
}


/* whatsapp */
.whatsapp{
  z-index: 5;
 position: fixed;
 right: 10px;
 bottom: 40px;

}

.whatsapp img{
  height: 70px;

}
.whatsapp :hover{
height:60px ;
cursor: pointer;
}

/* about-item-card-hover-effect */
.about-item:hover{
  transform: scale(1.1);
}
.about-item{
  transition: transform .5s;
}

.about-item h1{
  margin-top: 10px;
  font-size: large;
  font-weight:bold;

    
    }
    .about-item p{
      margin-top: -8px;
      font-size: large;
      font-weight:bold;

        
        }
.about-cart{
display: flex;
justify-content: space-between;
}
.about-summary{
color: #000;
}
/* right-side css */
.right-side{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

}
.about-license-pic{
height: 300px;
width: 300px;
margin: 50px;

}
.about-license-pic img{
width: 250px;
height: 250px;



}
.box{
display: flex;
justify-content: center;
align-items: center;
height:150px;
width:400px;
background-color: #F19A17;
border-radius: 20px;
margin: 50px;
}

/* Banner css start */


/* Slider heading Tuto Text H1 Css */
.slider{
margin-top:60px ;
margin-left:50px;
font-size:41px;
font-weight:500;
line-height:55px;
}

.carousel-slider{
  background-color:#d0d3da;
  margin-top:5px;
  height: 18rem;
  width: 19rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
/* slider image */
.slider-img{
  height: 15rem;
  width: 22rem;
  object-fit:contain;
   margin-left: -25px;
}
.slider-heading{
   color:black;
   background-color: white;
 font-size:20px;
}
.slider-para{
margin-top: -32px;
background-color: white;
/* margin-top:-1px; */
}
.slider-span{
  font-weight:500;
}


/* Service Paragraph (License ) */
.service-para{
  font-family: Arial, Helvetica, sans-serif;
}


/* License Card */
.licenseCard{
box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
/* webpage Card  */
.licenseCard1{
  height: 250px;
}
.licenseCard1 img{
  height: 250px;
}
.web-link a  {
  text-decoration: none;
}
/* hover effect Card  */
.card:hover{
transform: scale(1.1);
}
.card{
transition: transform .5s;
}




.view-more {
border-radius: 10px;
background-color: #f1b244;
border: none;
text-decoration: none;

font-weight: 400;
color: #050505;
text-align: center;
font-size: 32px;
padding: 15px;
width: 220px;
transition: all 0.7s;
cursor: pointer;
margin-top: 36px;
box-shadow: 0 10px 20px -8px rgba(0, 0, 0,.7);
}

.view-more{
cursor: pointer;
display: inline-block;
position: relative;
transition: 0.5s;
}

.view-more:after {
content: '>>';
position: absolute;
opacity: 0;  
top: 14px;
right: -20px;
transition: 0.5s;
}

.view-more:hover{
padding-right: 24px;
padding-left:8px;
background-color:  #5ca1e1;
color: #ddd;
font-weight: 500;
}

.view-more:hover:after {
opacity: 11;
right: 10px;
}



/* Lic-card contactUs */

/*  view License  */
.lic-heading{
  background-color: #F19A17;
  margin-bottom: 20px;
}
.lic-heading a{
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  margin-top: 10px;
  text-decoration: none;
  color: #050505;
} 

.lic-heading p{
  font-family: Arial, Helvetica, sans-serif;
}
.lic-card h3{
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  margin-top: 10px;
}
.lic-card p{
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
  
}
.lic-card {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  background-color: #ffffff;
  margin-top: 40px;
}
.lic-card1 {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; background-color: #ffffff;
  border-radius: 5px;
  margin-top: 40px;

}
/* License ContactUS main */
.lic-contactUs{
  padding-bottom: 20px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
/*  License ContactUS  icon Link */
.service-icon div {
  padding-top: 10px;
  padding-left: 16px;
}
.service-icon div a{ 
  background-color:rgb(241, 239, 232);
  height: 120px;
  width: 120px;
  text-align: center;
  border-radius: 20%;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.service-icon div a img{
  margin-top: 15px;
}
.contact-h5{
margin-top: 40px;
color: black;
}
.service-icon div a:hover{
  background-color: #fccf55;
}



/* testimonials */
.testi-main{
 margin-bottom: 10px;

}

.card-testi{
  background-color: hsla(253, 84%, 22%, 0.062);
  color: #000000;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  padding-bottom: 30px;
  }
  .testi-img{
    margin: auto;
  }
  .profile{
    font-size: 16px;
    margin-bottom: 10px;
  }
/* .carousel-item {
text-align: center;
padding: 40px;
}

.carousel-item .testimonial-text {
font-size: 1.2rem;
font-style: italic;
color: #555;
}

.carousel-item .testimonial-author {
font-weight: bold;
margin-top: 20px;
color: #333;
}

.carousel-item .testimonial-role {
color: #777;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
filter: invert(1);
} */

/* Query  Form */
.query-form{
box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
width: 90%;
background-color: #F19A17;
margin: auto;
margin-bottom: 40px;
border-radius: 10px;
}

.btn-query{
background-color: skyblue;
font-weight: 700;
}




/* Our Services */

.our-services{
background-color:white;
width: 90%;
margin: auto;
box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
margin-bottom: 20px;
/* margin-bottom: 80px; */


}
.our-ser-container{
  margin: auto;
  background-color:white;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  
margin: auto;
background-color:white;
display: grid;
grid-template-columns: repeat(4, 1fr);
margin-top: 20px;
text-decoration: none;
list-style: none;
}
.items{
margin-bottom: 10px;
font-size: 20px;
text-align: center;

}
.items a{
text-decoration: none;
} 







/* web development all services social media, seo, web, logo etc. */
/* ----------------------------------------------------------------- */
.social-main{
  height: 184rem;
}
.social-banner{
  height: 12rem;
  background: rgb(233, 233, 233);
}
.social-banner>h1{
  font-size: 3rem;
  padding-top: 2.5rem;
  font-family: "Varela Round", sans-serif;
  font-weight: 100;
  /* font-style: normal; */
}
/* .tellus {
  border-radius: 10px;
  background-color: #f1b244;
  border: none;
  text-decoration: none;
  font-weight: 400;
  color: #050505;
  text-align: center;
  font-size: 32px;
  padding: 15px;
  width: 300px;
  transition: all 0.7s;
  cursor: pointer;
  margin-top: 36px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0,.7);
  }
  .tellus{
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
  }
  .tellus:after {
  position: absolute;
  opacity: 0;  
  top: 14px;
  right: -20px;
  transition: 0.5s;
  }
  .tellus:hover{
  padding-right: 24px;
  padding-left:8px;
  background-color:  #5ca1e1;
  color: #ddd;
  font-weight: 400;
  }
  .tellus:hover:after {
  opacity: 11;
  right: 10px;
  } */
.social-descr>p{
  text-align: justify;
  text-align: center;
}
.social-descr{
 
  background:  rgb(247, 250, 249);
}

.tellusbtn{
  background:#F19A17 ;
  margin-left: 42%;
  margin-top: 4rem;
  border-radius: 32px;
  height: 3.2rem;
  border: 1px solid rgba(0, 0, 0, 0.274);
  
}
.tellus-div{
  /* margin: auto; */
  width: 100%;
  padding-bottom: 20px;
  /* background:yellowgreen ; */
}
.tellusbtn>a{
  text-decoration: none;
  color: #000000;
  font-size: 1.1rem;
}
.tellusbtn:hover{
  background:#ffb342 ;
  border: 1px solid rgba(0, 0, 0, 0.274);
}
.social-about{
   height: 450px;
   /* background: #1977cc; */
   width: 100vw;
   margin-top: 50px;
}
.social-about-left{
  width: 50%;
  /* background: red; */
}
.social-about-left>img{
  width: 45vw;
  height: 400px;
  object-fit: contain;
}
.social-about-right{
  width: 50%;
}
.work-with-main{
  background: rgb(12, 12, 12);
}
.work-with-main>div>h1{
  color: white;
  font-family: "Varela Round", sans-serif;
  padding-top: 30px;

}
.underline-work-with{
  height: 2px;
  width: 10rem;
  background: #F19A17;
  margin-bottom: 15px;
}
.work-with-main>div>p{
  color: rgb(148, 147, 147);
  font-weight: 100;
}
.work-with-btn{
  background:#F19A17 ;
  margin-left: 0%;
  margin-top: 1rem;
  border-radius: 32px;
  height: 3.2rem;
  border: 1px solid rgba(0, 0, 0, 0.274);
}
.work-with-btn>a{
  text-decoration: none;
  color: #000000;
  font-size: 1.1rem;
}
.work-with-btn:hover{
  background:#ffb342 ;
  border: 1px solid rgba(0, 0, 0, 0.274);
}
.title-n{
  color: rgb(148, 147, 147);
  font-weight: 400; 
  margin-left: 15rem;
}
.progress-bar{
  background: rgba(247,186,36,255); 
}
.professional{
  background:#ffb342 ;
  margin-top: 2rem;
  padding-bottom: 2rem;
}
.professional>h3{
   font-weight: 400;
   margin-top: 4rem;
  font-family: "Varela Round", sans-serif;
}
.underline-professional{
  height: 3px;
  width: 10rem;
  margin: auto;
  background: white;
}
.professional-img{
  height: 80px;
  width: 80px;
  background: #82cdff;
  border-radius: 50%;
  margin-left: 47%;
  margin-top: 20px;
}
.professional-img>img{
  height: 60px;
  width: 60px;
  margin-left: 8px;
  margin-top: 8px;
}
.professinol-para{
  font-weight: 500;
  padding-top: 5px;
}
.professinol-description{
  color: white;
  font-weight: 400;
}
.contact-h{
  font-family: "Varela Round", sans-serif;
  font-weight: 300;
  margin-top: 3rem;
  margin-left: 4rem;
}
.underline-contact{
  height: 3px;
  width: 10rem;
  margin-left: 5.5rem;
  margin-bottom: 20px;
  background: #ffb342;
}
.contact-p{
  font-family: "Varela Round", sans-serif;
  font-weight: 300;
  margin-left: 4.5rem;
}
.partner-main{
  background: #f3f3f3;
  height: 10rem;
}
.partner{
  font-family: "Varela Round", sans-serif;
}
.contact-partner>img{
  margin-left: 12.5rem;
  margin-top: 20px;
}




























/* web devlopment media query */
@media (min-width: 300px) and (max-width: 576px)  {
  .social-main{
    height: 278rem;
  }
  .social-banner>h1{
    font-size: 2.2rem;
  }
  .social-descr>p{
    text-align: justify;
  }
  .tellusbtn{
     /* margin: auto; */
    margin-left:10%;
    margin-top: 1.5rem;
    border-radius: 32px;
    height: 3.2rem;
    border: 1px solid rgba(0, 0, 0, 0.274);
  }
  .social-about{
    height: 450px;
    width: 100vw;
    margin-top: 20px;
 }
 .social-about-left{
   width: 100%;
   height: 190px;
 }
 .social-about-left>img{
   width: 94vw;
   height: 190px;
   object-fit: fill;
 }
 .social-about-right{
   width: 100%;
 }
 .social-about-right>h1{
  margin-top: 20px;
  font-size: 26px;
  font-family: "Varela Round", sans-serif;
 }
 .social-about-right>p{
  text-align: justify;
 }
 .work-with-main{
  margin-top: 16rem;
}
.title-n{
  margin-left: 0rem;
}
.pull-right{
  margin-left: 6rem;
}
.skill-mf{
  margin-bottom: -15px;
}
.professional-img{
  height: 80px;
  width: 80px;
  background: #82cdff;
  border-radius: 50%;
  margin-left: 39%;
  margin-top: 20px;
}
.professinol-description{
text-align: justify;
}
.contact-h{
  font-family: "Varela Round", sans-serif;
  font-weight: 300;
  margin-top: 3rem;
 margin-left: 3px;

}
.underline-contact{
  height: 3px;
  width: 10rem;
 margin-left: 17px;
  margin-bottom: 20px;
  background: #ffb342;
}
.contact-p{
 margin-left: 3px;
  font-family: "Varela Round", sans-serif;
  font-weight: 300;
}
.partner-main{
  background: #f3f3f3;
  height: 8rem;
}
.partner{
  font-family: "Varela Round", sans-serif;
}
.contact-partner{
  display: flex;
}
.contact-partner>img{
  margin-left: 0.6rem;
  margin-top: 20px;

}


}































/* end web development services like social media , seo, logo ,web */

/* comming soon */
/* .second{
  height: 80%;
  width: 100%;
}
.second>img{
  object-fit:cover;
  height:100%;
  width: 100%;
  margin-bottom: -52px;
}
.btn-com{
  margin-left: 30%;
}
.btn-com>a{
  text-decoration: none;
  color: white;

} */

/* comming soon */


/* Marquee css */
.scrolling-container {
  animation: scroll 20s alternate infinite;
  color: red;
  font-family: sans-serif;

  direction: right;
} 

@keyframes scrolling-tex  {
  50% {
      opacity: 0;
  }
} 


.scrolling-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}



@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
}


/* Admin  License form */
.license-create-form{
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  padding-bottom: 20px;
}
.admin-info{
border-radius: 10px;  
height: 55px;
box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
background: -webkit-linear-gradient(90deg, hsla(49, 100%, 48%, 1) 3%, hsla(226, 39%, 52%, 1) 72%);
}
/* Admin side-bar */
 .side-bar{
box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;  
 }
/* admin create form  */
.form-label{
  font-weight: 500;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
}

/* teams */

.doctors .team-member {
  background-color: var(--surface-color);
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  border-radius: 5px;
  transition: 0.5s;
  padding: 30px;
  height: 100%;
}
.mm{

margin: auto;

}

@media (min-width: 468px) and (max-width: 1426px) {
   .social1 {
   margin-left: 68px;
  }
}

@media (max-width: 468px) {
  .doctors .team-member {
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
  }
}

.doctors .team-member .pic {
  overflow: hidden;
  width: 100px;
  border-radius: 50%;
  height: 100px;
  flex-shrink: 0;
}

.doctors .team-member .pic img {
  transition: ease-in-out 0.3s;
}

.doctors .team-member:hover {
  transform: translateY(-10px);
}

.doctors .team-member .member-info {
  padding-left: 30px;
}

@media (max-width: 468px) {
  .doctors .team-member .member-info {
    padding: 30px 0 0 0;
    text-align: center;
  }
}

.doctors .team-member h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
}

.doctors .team-member span {
  display: block;
  font-size: 15px;
  padding-bottom: 10px;
  position: relative;
  font-weight: 500;
}

.doctors .team-member span::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 1px;
  background: color-mix(in srgb, var(--default-color), transparent 85%);
  bottom: 0;
  left: 0;
}

@media (max-width: 468px) {
  .doctors .team-member span::after {
    left: calc(50% - 25px);
  }
}

.doctors .team-member p {
  margin: 10px 0 0 0;
  font-size: 14px;
}

.doctors .team-member .social {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  /* margin-left: 68px; */
  
}

@media (max-width: 468px) {
  .doctors .team-member .social {
    justify-content: center;
  }
}

.doctors .team-member .social a {
  background: color-mix(in srgb, #444444, transparent 87%);
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 36px;
  height: 36px;
}

.doctors .team-member .social a i {
  color: color-mix(in srgb, #444444, transparent 20%);
  font-size: 16px;
  margin: 0 2px;
}

.doctors .team-member .social a:hover {
  background: #1977cc;
  color: white;
}

.doctors .team-member .social a:hover i {
  color: #ffffff;
}

.doctors .team-member .social a+a {
  margin-left: 8px;
}

/* teams */






/* footer */
.bg{
background:  #c5c1bf;

}
.footer-img img{
  height: 100px;
  width: 100px;


}
.footer{
  margin-top:100px;
}

.footer-link a{
  color: white;
  text-decoration: none;
}
.web {
  font-size: 14px;
}
.footer-link :active{
  color: #F19A17
}
.footer-link a:hover {
 color: #F19A17;
 font-size: 15px;
}
.footer-icon:hover{
  color: #000;
}
.goToTop-btn{
  cursor: pointer;
  font-size: 32px;
}
.goToTop-btn:hover{
  font-size: 35px;
}


/* --------------------------------------- MEDIA QUERY SECTION --------------------------------------------- */
/* Navbar Logo  */
@media (max-width: 575px) {
.navbar div h1{
  margin-left: -50px;
}
.dropbtn {
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  margin-left: 32px;
}
}

@media (max-width: 575px){
.active{
  border-bottom:none;
}
}
@media (min-width: 767px) and (max-width:991px){
  .dropbtn {
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    margin-left: 32px;
  }
  .active{
    border-bottom:none;
  }
  }


/* slider h1 Media Query 0- 410px  css */
@media (max-width: 575px) {
  .slider{
     font-size: 27px; 
     line-height:40px;
     margin-top:30px ;
     margin-left: 4px;
     font-weight:800;
    }
    .carousel-slider{
      background-color:#d0d3da;
      margin-top: 5px;
      height: 21rem;
      /* margin-left: 10px; */
      margin: auto;
      width: 18rem;
      margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    }
    .slider-img{
      height: 18rem;
      width: 18rem;
      object-fit:contain;
      margin-left: 1px;

    }
    .slider-para{
      color: rgb(0, 0, 0);
     /* margin-left: 5px; */
     background-color: white;

    }
    .slider-span{
      font-weight: 400;
    }
    
  }

/* Slider Image 576ox -767px (show two card) */
  @media (min-width: 576px) and (max-width: 768px)  {
    .dropbtn {
      color: white;
      padding: 16px;
      font-size: 16px;
      border: none;
      margin-left: 32px;
    }
      .carousel-slider{
        background-color:#d0d3da;
        margin-top: 5px;
        height: 23rem;
        /* padding-left: 10px; */
        margin: auto;
        width: 25rem;
        margin-bottom: 20px;
       box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
      }
      .slider-img{
        height: 20rem;
       object-fit:contain;
        width: 30rem;
        /* margin: auto; */
      }
      .slider-para{
        color: rgb(0, 0, 0);
       
  
      }
      .slider-span{
        font-weight: 400;
      }
      .active{
        border-bottom:none;
      }
      
    }

/* start banner Media Query */
@media (max-width: 600px) {
  .banner{
    height: 250px;
    width: 100%;
  }
  .banner img{
    height: 220px;
    width: 100%;
  }
  .web-banner{
    height: 190px;
    width: 100%;
  }
  .web-banner img{
    object-fit:fill;
    width: 100%;
    height: 190px;
  }
  }

/* end banner Media Query */
  
  
  @media only screen and (max-width: 600px) {
      .about-cart{
          display: flex;
          justify-content: space-between;
  
          flex-direction: column;
        align-items: center;
  
        
        }
      }

/*start-about-section-Media-Query */
 @media only screen and (max-width: 600px) {
      .about-cart{
          display: flex;
          justify-content: space-between;
      }
    }


/* Our Services Links */
@media (max-width: 600px) {
  .items{
    margin-bottom: 10px;
    font-size: 10px;
    margin: 5px 0px;
  }
  .our-ser-container{
    margin: auto;
    background-color:white;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 8px;
    text-decoration: none;
    list-style: none;
    /* grid-gap:0px; */
  }
  
}


/* Our Services Links sceern size width 600px -767px */
@media (min-width: 600px) and (max-width :767px){
  .items{
    margin-bottom: 10px;
    font-size: 10px;
    margin: 5px 0px;
  }
  .our-ser-container{
    margin: auto;
    background-color:white;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 8px;
    text-decoration: none;
    list-style: none;
    /* grid-gap:0px; */
  }
  
}

/* ContactUs Page Media Query */

@media only screen and (max-width: 600px) {
    .about-cart{
        display: flex;
        justify-content: space-between;

        flex-direction: column;
      align-items: center;

      
      }
    }

    @media only screen and (max-width: 600px) {
      .about-item{
       margin:20px 0;
       height: 275px;
       width: 275px;
       font-weight:800;
        
        }
      }
      
      @media only screen and (max-width: 600px) {
        .about-item{
         margin:20px 0;
         height: 275px;
         width: 275px;
        
        

          
          }
          .about-item h1{
            margin-top: 8px;
            font-size: large;
            font-weight:600;

              
              }
              .about-item p{
                font-weight:600;
         font-size: large;

                  
                  }
        }
         
        @media only screen and (max-width: 600px) {
          .box{
           height: 150px;
           width: 300px;
           margin: 20px;
            }
          }
          @media only screen and (max-width: 600px) {
            .text-1{
           font-size:large;
           font-weight: bold;
           padding: 5px;
           background-color: white;
              }
            }
/*end-about-section-Media-Query */
  
         
  
        
